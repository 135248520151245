<template>
    <form :class="{isSubmitted: formStats.isSubmitted}" @submit.prevent="submitThisForm">
        <slot/>
    </form>
</template>

<script>
    import get_error from "@songfinch/shared/helpers/get_error";
    import {captureException} from "@sentry/vue";

    export default {
        name: "FormAjax",
        provide() {
            return {
                formStats: this.formStats
            };
        },
        props: {
            submitAction: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                formStats: {
                    inProgress: false,
                    isSubmitted: false,
                    error: "",
                }
            };
        },
        methods: {
            async submitThisForm() {
                if (this.formStats.inProgress) return;
                this.formStats.error = "";
                this.formStats.inProgress = true;
                try {
                    const res = await this.submitAction();
                    this.formStats.error = res?.error;
                    this.formStats.isSubmitted = false;
                } catch (e) {
                    this.formStats.error = get_error(e);
                    captureException(e, {extra: {sfErrorIgonre: 1}});
                }
                this.formStats.inProgress = false;
            }
        }
    };
</script>
